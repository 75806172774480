import { Link } from "react-router-dom"
import { IEvent } from "./types"
import { useTranslation } from "react-i18next"
import {
  IconEventNumber1,
  IconEventNumber2,
  IconEventNumber3,
  IconEventNumber4,
  IconEventNumber5,
  IconLink,
} from "../utils/Icons/CustomIcons"
import moment from "moment"
import { useAppDispatch } from "@/redux/store"
import { clearQrId, setOpenHash } from "@/redux/slices/events/eventsSlice"

const EventsItem = ({
  event_id,
  status,
  name,
  event_created_date,
  pattern,
  count_all_contacts,
  count_contacts_going,
  count_contacts_decline,
  open_event_page_total_visits,
  open_event_hash,
  count_contacts_ignore,
  is_open_event,
}: IEvent) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const nameStatus = [
    { draft: t("events.filter.status.draft") },
    { approved: t("events.filter.status.approved") },
    { all: t("events.filter.status.all") },
    { updated: t("events.filter.status.updated") },
    { merged: t("events.filter.status.merged") },
    { sourceOfMerge: t("events.filter.status.sourceOfMerge") },
  ]

  return (
    <div className="event-item">
      <div className="event-left">
        <div className="event-picture">
          <img src={pattern ? pattern : "/vector/no-foto.svg"} alt={name} />
        </div>
        <div className="event-info">
          <Link
            to={`/${event_id}`}
            className="event-item__caption caption-three"
            onClick={() => {
              dispatch(clearQrId())
              dispatch(
                setOpenHash(is_open_event === 1 ? open_event_hash : null),
              )
            }}
          >
            {name}
          </Link>
          <span className="event-item__date">
            {moment(event_created_date).format("dddd, D MMMM YYYY")}
          </span>
          {is_open_event === 0 && (
            <span
              className={`event-item__status status ${Object.keys(
                nameStatus[status],
              )}`}
            >
              {Object.values(nameStatus[status])}
            </span>
          )}
          {window.innerWidth > 991 &&
            open_event_hash &&
            is_open_event === 1 && (
              <span className="event-item__link">
                <IconLink />
                <Link to={`/register/${open_event_hash}`}>
                  app.mazoom.sa/register/{open_event_hash}
                </Link>
              </span>
            )}
        </div>
      </div>
      {window.innerWidth < 992 && is_open_event === 1 && open_event_hash && (
        <span className="event-item__link">
          <IconLink />
          <Link to={`/register/${open_event_hash}`}>
            app.mazoom.sa/register/{open_event_hash}
          </Link>
        </span>
      )}
      <div className="event-right">
        <ul className="event-num">
          <li>
            <IconEventNumber1 />
            <span>{count_all_contacts}</span>
          </li>
          <li>
            <IconEventNumber2 />
            <span>{count_contacts_going}</span>
          </li>
          <li>
            <IconEventNumber3 />
            <span>{count_contacts_decline}</span>
          </li>
          <li>
            {is_open_event === 1 ? <IconEventNumber5 /> : <IconEventNumber4 />}
            <span>
              {is_open_event === 1
                ? open_event_page_total_visits
                : count_contacts_ignore}
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default EventsItem
