import { useEffect, useState } from "react"
import Switch from "react-switch"
import { useTranslation } from "react-i18next"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Controller } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import { useJsApiLoader } from "@react-google-maps/api"
import { getGeocode } from "use-places-autocomplete"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  clearEventId,
  clearQrId,
  createEvent,
  eventDesign,
  getEventByHash
} from "../../redux/slices/events/eventsSlice"
import CreateEventField from "../../components/CreateEvent/CreateEventField"
import ChooseFileEvent from "../../components/CreateEvent/ChooseFileEvent"
import SelectTypeEvent from "../../components/EditEvent/SelectTypeEvent"
import SelectLanguageEvent from "../../components/EditEvent/SelectLanguageEvent"
import DateEvent from "../../components/EditEvent/DateEvent/DateEvent"
import Location from "../../components/CreateEvent/location/Location"
import AutocompleteField from "../../components/CreateEvent/location/AutocompleteField"
import {
  IconCrown,
  IconProfileInfo,
} from "../../components/utils/Icons/CustomIcons"
import AboutModal from "@/components/CreateEvent/AboutModal/AboutModal"
import OpenModal from "@/components/EditEvent/OpenModal/OpenModal"
import DeleteQuestionModal from "@/components/CreateEvent/DeleteQuestionModal/DeleteQuestionModal"
import RegisterLinkModal from "@/components/CreateEvent/RegisterLinkModal/RegisterLinkModal"

// google map
const API_KEY = import.meta.env.VITE_REACT_APP_GOOGLE_API_KEY

const defaultCenter = {
  lat: -3.745,
  lng: -38.523,
}

const notify = (text: string, color: boolean) => {
  color
    ? toast.error(text, {
        position: "top-right",
        autoClose: 3000,
      })
    : toast.success(text, {
        position: "top-right",
        autoClose: 3000,
      })
}

const CreateEventForm = ({
  setEvent,
  event,
  new_event_img,
  error,
  onCancelHandle,
  language,
  setLanguage,
  setShowOpen,
  showOpen,
  setAddress,
  address,
  register,
  control,
  errors,
  setError,
  clearErrors,
}: any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const [values, setValues] = useState<any>([])
  const [id, setId] = useState(0)
  const [isOpenLocationPopup, setIsOpenLocationPopup] = useState(false)
  const [center, setCenter] = useState(defaultCenter)
  const { currentEvent, new_event_id } = useAppSelector(
    (state) => state.events,
  )
  const { event_id } = useParams()
  const { is_premium } = useAppSelector((state) => state.auth)

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
    libraries: ["places"],
  })

  const checkEmptyInput = (value: string) => {
    if (!!!value) {
      return "empty"
    }
  }

  const onSubmit = (e: any, manage: string) => {
    e.preventDefault()
    clearErrors()
    if (new_event_img.id === 0 && new_event_id === null) {
      setError("file", { type: "custom", message: "This field is required" })
    } else if (event.name === "" && event.address.address === "") {
      setError("name", { type: "custom", message: "This field is required" })
      setError("address", { type: "custom", message: "This field is required" })
    } else if (event.name === "") {
      setError("name", { type: "custom", message: "This field is required" })
    } else if (event.address.address === "") {
      setError("address", { type: "custom", message: "This field is required" })
    } else {
      dispatch(
        createEvent({
          ...event,
          address: {
            ...event.address,
            address: address.address ? address.address : address.venue,
          },
          venue: address.address ? address.venue : "",
        }),
      )
        .then((data: any) => {
          if (data.payload.errors) {
            event.address.address === "" &&
              setError("address", {
                type: "custom",
                message: "This field is required",
              })
            Object.entries(data.payload.errors).forEach(
              ([field, messages]: any) => {
                messages.forEach((message: any) => {
                  setError(field, { type: "custom", message })
                })
              },
            )
            notify(
              (Object.values(data.payload.errors) as string[][])[0][0],
              true,
            )
          } else {
            let eventD = {
              event_id: data.payload.data.event_id,
              pattern_id: new_event_img.id
                ? new_event_img.id
                : currentEvent?.pattern_id,
            }
            new_event_img.id === 0 && currentEvent === null
              ? notify("The selected pattern id is invalid", true)
              : dispatch(eventDesign(eventD)).then((res: any) => {
                  if (res.payload.data) {
                    if (manage === "") {
                      dispatch(clearEventId())
                      notify("Event created", false)
                      navigate("/events")
                      dispatch(clearQrId())
                    } else if (manage === "qr") {
                      dispatch(clearQrId())
                      event_id && navigate(`/qr-design/${event_id}`)
                    } else {
                      setShowOpen(true)
                      currentEvent?.open_event_hash &&
                        dispatch(getEventByHash(currentEvent?.open_event_hash))
                    }
                  }
                })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const onPlaceSelect = (address: any, coordinates: any) => {
    setEvent({
      ...event,
      address: {
        address: address,
        lat: coordinates.lat,
        lng: coordinates.lng,
      },
    })
    setCenter(coordinates)
  }

  const onClickSaveMap = (location: any) => {
    const latlng = {
      lat: location.lat,
      lng: location.lng,
    }
    getGeocode({ location: latlng }).then((results: any) => {
      setAddress({...address, address: results[0].formatted_address, venue: ''})
      setEvent({
        ...event,
        address: {
          address: results[0].formatted_address,
          lat: location.lat,
          lng: location.lng
        },
      })
    })
  }

  const onClickMap = (location: any) => {
    const latlng = {
      lat: location.latLng.lat(),
      lng: location.latLng.lng(),
    }
    setCenter(latlng)
  }

  const locationPopupOpen = () => {
    setIsOpenLocationPopup(true)
  }

  const locationPopupClose = () => {
    setIsOpenLocationPopup(false)
    onClickSaveMap(center)
  }

  useEffect(() => {
    currentEvent && setEvent({ ...event, language: event.language })
  }, [currentEvent])

  return (
    <div className="createEventForm__left">
      <form action="" className="create-form">
        <ChooseFileEvent error2={errors.file?.message || error} />

        <CreateEventField
          name={"name"}
          type="text"
          label={"Enter event name*"}
          value={event.name}
          register={register}
          error={errors.name?.message || error}
          className={checkEmptyInput(event.name)}
          onHandleChange={(value) => {
            setEvent({
              ...event,
              name: value,
            })
          }}
        />

        <Controller
          name="address"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <AutocompleteField
              setAddress={setAddress}
              address={address}
              event={event}
              setEvent={setEvent}
              onSelect={onPlaceSelect}
              locationPopupOpen={locationPopupOpen}
              isLoaded={isLoaded}
              error={errors.address?.message}
              handleChange={(val: any): void => {
                onChange(val)
              }}
            />
          )}
          rules={{ required: t("errors.fieldRequired") }}
        ></Controller>

        {isLoaded && (
          <Location
            isOpenLocationPopup={isOpenLocationPopup}
            handleClose={locationPopupClose}
            event={event}
            onClickMap={onClickMap}
            center={center}
            isLoaded={isLoaded}
          />
        )}

        <Controller
          name="eventType"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <SelectTypeEvent
              event={event}
              setEvent={setEvent}
              placeholder={"Choose event type"}
              value={value || event.type}
              error={errors.eventType?.message}
              handleChange={(val: any): void => {
                onChange(val)
              }}
              // error={errors.eventType?.message || error}
            />
          )}
          rules={{ required: t("errors.fieldRequired") }}
        ></Controller>

        <Controller
          name="start_datetime"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <DateEvent
              event={event}
              setEvent={setEvent}
              error={errors.start_datetime?.message}
              handleChange={(val: any): void => {
                onChange(val)
              }}
            />
          )}
          rules={{ required: t("errors.fieldRequired") }}
        ></Controller>

        <Controller
          name="language"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <SelectLanguageEvent
              event={event}
              setEvent={setEvent}
              placeholder={"Invitation text language"}
              value={event.language}
              error={errors.language?.message}
              language={language}
              setLanguage={setLanguage}
              handleChange={(val: any): void => {
                onChange(val)
              }}
            />
          )}
          rules={{ required: t("errors.fieldRequired") }}
        ></Controller>

        <div className="checkbox-list">
          <div className="checkbox-item">
            <input
              type="checkbox"
              id="qr-confirm"
              disabled={is_premium ? false : true}
              checked={event.invitation_show_qr_code}
              onChange={() => {
                setEvent({
                  ...event,
                  invitation_show_qr_code: !event.invitation_show_qr_code,
                })
              }}
            />
            <label htmlFor="qr-confirm">Send QR code on confirm</label>
          </div>
          <div className="checkbox-item">
            <input
              type="checkbox"
              id="qr-wallet"
              checked={event.qr_wallet}
              disabled={is_premium ? false : true}
              onChange={() => {
                setEvent({
                  ...event,
                  qr_wallet: !event.qr_wallet,
                })
              }}
            />
            <label htmlFor="qr-wallet">
              Add QR to Apple Wallet and Google Pay
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className="header-tooltip" id="tooltip">
                    This feature is only available for premium users. Purchase
                    premium subscription to get this and many other features
                  </Tooltip>
                }
              >
                <span>
                  <IconCrown />
                </span>
              </OverlayTrigger>
            </label>
          </div>
        </div>

        <div className="create-form__switcher">
          <label htmlFor="material-switch" className="switcher-label">
            <Switch
              onChange={() =>
                setEvent({
                  ...event,
                  is_open_event: event.is_open_event === 1 ? 0 : 1,
                })
              }
              checked={event.is_open_event === 1 ? true : false}
              uncheckedIcon={false}
              disabled={true}
              checkedIcon={false}
              boxShadow="0px"
              activeBoxShadow="0px"
              width={60}
              height={28}
              handleDiameter={24}
              className={`switcher-button ${
                event.is_open_event === 1 ? "switched" : ""
              }`}
            />
            <h5 className="switcher-check">Open event</h5>
            <button className="switcher-question" onClick={() => setShow(true)}>
              <IconProfileInfo color="dark" />
            </button>
            <button
              className="btn btn-dark switcher-btn"
              style={{
                display:
                  window.screen.width < 768 && event.is_open_event === 0
                    ? "none"
                    : "inline-block",
              }}
              type="submit"
              onClick={(e: any) => onSubmit(e, "manage")}
            >
              Manage
            </button>
          </label>
        </div>

        <div className="create-form__controls">
          <span className="btn btn-simple" onClick={onCancelHandle}>
            {t("buttons.cancel")}
          </span>
          <button
            type="submit"
            className="btn btn-dark"
            onClick={(e: any) => onSubmit(e, "")}
          >
            {t("buttons.save")}
          </button>
        </div>
      </form>
      <DeleteQuestionModal
        show={showDelete}
        setShow={setShowDelete}
        setShowOpen={setShowOpen}
        setValues={setValues}
        values={values}
        id={id}
      />
      <AboutModal show={show} setShow={setShow} />
      <RegisterLinkModal show={showRegister} setShow={setShowRegister} />
      <OpenModal
        show={showOpen}
        setShow={setShowOpen}
        setValues={setValues}
        values={values}
        setId={setId}
        setShowDelete={setShowDelete}
        setShowRegister={setShowRegister}
      />
    </div>
  )
}

export default CreateEventForm
