import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Select from "react-select"
import { ToastContainer, toast } from "react-toastify"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useAppSelector } from "../../redux/store"
import {
  IconArrowLeft,
  IconBalance,
  IconBtnCreate,
  IconProfileInfo,
} from "../../components/utils/Icons/CustomIcons"
import TestField from "../../components/Test/TestField"
import TestModal from "../../components/Test/TestModal/TestModal"
import Preloader from "../../components/utils/Preloader/Preloader"
import "react-toastify/dist/ReactToastify.css"

const TestInvitationPage = () => {
  const { t } = useTranslation()
  const { event_id } = useParams()
  const { balance, phone, lang, first_name, last_name, isLoading } =
    useAppSelector((state) => state.auth)

  let defaultPersonal = {
    first_name: "",
    last_name: "",
    suffix: "Mr",
    phone: "",
  }
  const [personal, setPersonal] = useState(defaultPersonal)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
  })
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()

  const checkEmptyInput = (value: string) => {
    if (!!!value) {
      return "empty"
    }
  }

  useEffect(() => {
    setPersonal({
      ...personal,
      first_name: first_name ? first_name : "",
      last_name: last_name ? last_name : "",
      phone: phone ? phone : "",
    })
  }, [phone, first_name, last_name])

  const options =
    lang === "ar"
      ? [
          { value: "المكرمة", label: "المكرمة", id: 3 },
          { value: "المكرم", label: "المكرم", id: 4 },
          { value: "الاستاذ", label: "الاستاذ", id: 5 },
        ]
      : [
          { value: "Mr", label: "Mr", id: 0 },
          { value: "Mrs", label: "Mrs", id: 1 },
          { value: "Dr", label: "Dr", id: 2 },
        ]

  const notify = (text: string) => {
    toast.warning(text, {
      position: "top-right",
      autoClose: 3000,
    })
  }

  const onSend = () => {
    setErrors({
      first_name: "",
      last_name: "",
    })
    if (personal.first_name === "" && personal.last_name === "") {
      setErrors(
        lang === "ar"
          ? {
              first_name: "حقل الاسم الأول مطلوب",
              last_name: "حقل الاسم الأخير مطلوب",
            }
          : {
              first_name: "The first name field is required",
              last_name: "The last name field is required",
            },
      )
    } else if (personal.last_name === "") {
      setErrors(
        lang === "ar"
          ? {
              first_name: "",
              last_name: "حقل الاسم الأخير مطلوب",
            }
          : {
              first_name: "",
              last_name: "The last name field is required",
            },
      )
    } else if (personal.first_name === "") {
      setErrors(
        lang === "ar"
          ? {
              first_name: "حقل الاسم الأول مطلوب",
              last_name: "",
            }
          : {
              first_name: "The first name field is required",
              last_name: "",
            },
      )
    } else {
      setErrors({
        first_name: "",
        last_name: "",
      })
      if (balance === 0) {
        notify("Please top up your balance")
      } else {
        setShowModal(true)
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <Preloader />
      ) : (
        <div className="test-section">
          <div className="container">
            <div className="event-page__link">
              <Link
                className="d-flex align-items-center event-page__link-container"
                to={`/${event_id}`}
              >
                <IconArrowLeft />
                <span className="event-page__link-text">{t("test.back")}</span>
              </Link>
            </div>
            <div className="title-page">
              <h2 className="caption-two">{t("test.titlePageTest")}</h2>
            </div>

            <div className="test-row">
              <div className="test-col test-col--plan bl-default">
                <div className="test-left">
                  <h2 className="caption caption-three">
                    {t("test.leftBlock")}
                  </h2>
                </div>
              </div>

              <div className="test-col test-col--balance bl-default">
                <div className="test-left">
                  <h2 className="caption caption-three">
                    {t("test.availableBalance")}
                  </h2>

                  <div className="test-info">
                    <IconBalance />
                    <span className="test-balance__value">
                      {balance} {t("test.credits")}
                    </span>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip className="test-tooltip" id="tooltipCredits">
                          {t("test.tooltip.credits")}
                        </Tooltip>
                      }
                    >
                      <div className="test-tooltip__content">
                        <IconProfileInfo color="dark" />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>

                <Button
                  className="btn btn-dark"
                  onClick={() => navigate("/credits")}
                >
                  <IconBtnCreate />
                  {t("buttons.topUP")}
                </Button>
              </div>

              <div className="test-col bl-default test-col-form">
                <div className="test-col__head">
                  <h2 className="caption caption-three">
                    {t("test.followingTitle")}
                  </h2>
                </div>
                <div className="test-col__body">
                  <form className="test-form test-form--password">
                    <TestField
                      name={"first_name"}
                      type="text"
                      disabled={false}
                      label={t("placeholder.firstName")}
                      value={personal.first_name}
                      error={errors?.first_name}
                      className={checkEmptyInput(personal.first_name)}
                      onHandleChange={(value) => {
                        setPersonal({
                          ...personal,
                          first_name: value,
                        })
                      }}
                    />
                    <TestField
                      name={"last_name"}
                      type="text"
                      label={t("placeholder.lastName")}
                      value={personal.last_name}
                      error={errors?.last_name}
                      disabled={false}
                      className={checkEmptyInput(personal.last_name)}
                      onHandleChange={(value) => {
                        setPersonal({
                          ...personal,
                          last_name: value,
                        })
                      }}
                    />
                    <div className="test-select">
                      <Select
                        defaultValue={options[0]}
                        options={options}
                        isSearchable={false}
                        value={options.find((e) => e.value === personal.suffix)}
                        onChange={(val: any) => {
                          setPersonal({ ...personal, suffix: val?.value })
                        }}
                        styles={{
                          container: (state) => ({
                            ...state,
                            outline: "none",
                            border: "0",
                            position: "relative",
                            boxShadow: "none",
                          }),
                          valueContainer: (state) => ({
                            ...state,
                            display: "flex",
                            padding: "2px 8px 2px 0px",
                          }),
                          singleValue: (state) => ({
                            ...state,
                            color: "#71706F",
                            fontSize: "14px",
                            lineHeight: "26px",
                            "@media (max-width: 1279px)": {
                              fontSize: "12px",
                              lineHeight: "16px",
                            },
                          }),
                          control: (state) => ({
                            ...state,
                            fontSize: "14px",
                            lineHeight: "26px",
                            color: "#71706F",
                            outline: "none",
                            border: "none",
                            boxShadow: "none",
                            borderBottom: "1px solid #E3E2E2",
                            "&:hover": {
                              borderBottom: "1px solid #E3E2E2",
                            },
                          }),
                          indicatorSeparator: () => ({
                            display: "none",
                          }),
                          dropdownIndicator: (base: any, state) => ({
                            ...base,
                            transform: state.selectProps.menuIsOpen
                              ? "rotate(180deg)"
                              : null,
                          }),
                          option: (state) => ({
                            ...state,
                            fontSize: "14px",
                            lineHeight: "26px",
                            color: "#71706F",
                            backgroundColor: state.isSelected
                              ? "#F2F4F7"
                              : "#FFF",
                            "&:hover": {
                              background: "#F2F4F7",
                            },
                          }),
                          menuList: (state) => ({}),
                        }}
                      />
                      <label className={"active"} htmlFor="">
                        {t("placeholder.suffix")}
                      </label>
                    </div>
                    <TestField
                      name={"phone"}
                      type="text"
                      label={t("placeholder.phoneNumber")}
                      value={personal.phone}
                      error={""}
                      disabled={true}
                      className={checkEmptyInput(personal.phone)}
                      onHandleChange={(value) => {
                        setPersonal({
                          ...personal,
                          phone: value,
                        })
                      }}
                    />
                  </form>
                </div>
              </div>
              <div className="test-col-buttons">
                <span
                  className="btn btn-simple"
                  onClick={() => navigate(`/${event_id}`)}
                >
                  {t("buttons.cancel")}
                </span>
                <button className="btn btn-dark" onClick={onSend}>
                  {t("buttons.send")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
      />
      <TestModal show={showModal} setShow={setShowModal} personal={personal} />
    </>
  )
}

export default TestInvitationPage
