import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "./redux/store"
import { authentication, setLoading } from "./redux/slices/auth/authSlice"
import { clearMessages, setPage } from "./redux/slices/messages/messagesSlice"
import Header from "./components/Header/Header"
import SignUpPage from "./pages/Auth/SignUpPage"
import LoginPage from "./pages/Auth/LoginPage"
import ForgotPasswordPage from "./pages/Auth/ForgotPasswordPage"
import LoginWhatsAppPage from "./pages/Auth/LoginWhatsAppPage"
import Preloader from "./components/utils/Preloader/Preloader"
import PublicRoute from "./components/utils/PublicRoute"
import CreateEventPage from "./pages/CreateEvent/CreateEventPage"
import EventsPage from "./pages/Events/EventsPage"
import ProfilePage from "./pages/Profile/ProfilePage"
import QrDesignPage from "./pages/QrDisign/QrDesignPage"
import ContactsPage from "./pages/Contacts/ContactsPage"
import EventPage from "./pages/Event/EventPage"
import SelectContactsPage from "./pages/SelectContacts/SelectContactsPage"
import MessagesPage from "./pages/Messages/MessagesPage"
import RemindersPage from "./pages/Reminders/RemindersPage"
import TestInvitationPage from "./pages/TestInvitation/TestInvitationPage"
import TestInvitationStatusPage from "./pages/TestInvitation/TestInvitationStatusPage"
import ThankMessage from "./pages/ThankMessage/ThankMessagePage"
import CreditsPage from "./pages/Credits/CreditsPage"
import ResultPage from "./pages/Result/ResultPage"
import RegisterPage from "./pages/Register/RegisterPage"
import EventPageOpen from "./pages/Event/EventPageOpen"
import EditEventPage from "./pages/EditEvent/EditEventPage"
import ChangePasswordPage from "./pages/Auth/ChangePasswordPage"
import Message from "./pages/Auth/Message"
import WhoopsPage from "./pages/Auth/WhoopsPage"

const App = () => {
  const location = useLocation()
  const { isLoading } = useAppSelector((state) => state.auth)
  const { open_hash } = useAppSelector((state) => state.events)
  const dispatch = useAppDispatch()
  const [isMessages, setIsMessages] = useState<boolean>(false)
  const { accessToken } = useAppSelector((state) => state.auth)
  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem("token") || ""

    if (token) {
      dispatch(setLoading())
      dispatch(authentication(null))
    }

    if (location.pathname === "/messages") {
      setIsMessages(true)
    } else {
      dispatch(clearMessages())
      dispatch(setPage(1))
      setIsMessages(false)
    }
  }, [dispatch, location])

  useEffect(() => {
    const token = localStorage.getItem("token") || ""
    if (
      !token &&
      location.pathname.split("/")[1] !== "register" &&
      location.pathname !== "/auth/password"
    ) {
      navigate("/login")
    }
  }, [accessToken])

  return (
    <div className="wrapper">
      {isLoading && <Preloader />}

      <Header />

      <main
        className={`main py-sm-5 py-3 ${isMessages ? "main-messages" : ""}`}
      >
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/whatsapp" element={<LoginWhatsAppPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          </Route>
          <Route path="/auth/password" element={<ChangePasswordPage />} />
          {/* // <Route element={<PrivateRoute />}> */}
          <Route path="/" element={<EventsPage />} />
          <Route path="/register/:event_hash" element={<RegisterPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/credits" element={<CreditsPage />} />
          <Route path="/credits/result" element={<ResultPage />} />
          <Route path="/:event_id/reminders" element={<RemindersPage />} />
          <Route path="/messages" element={<MessagesPage />} />
          <Route
            path="/:event_id"
            element={open_hash !== null ? <EventPageOpen /> : <EventPage />}
          />
          <Route
            path="/:event_id/test-the-invitation"
            element={<TestInvitationPage />}
          />
          <Route path="/:event_id/thank-message" element={<ThankMessage />} />
          <Route
            path="/:event_id/test-the-invitation/status"
            element={<TestInvitationStatusPage />}
          />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/event-create" element={<CreateEventPage />} />
          <Route path="/event-edit/:event_id" element={<EditEventPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/:event_id/contacts" element={<SelectContactsPage />} />
          <Route path="/qr-design/:event_id" element={<QrDesignPage />} />
          {/* <Route path="/reset-password" element={<ResetPasswordPage />} /> */}
          <Route path="/password-success" element={<Message />} />
          <Route path="/password-failed" element={<WhoopsPage />} />
        </Routes>
      </main>
    </div>
  )
}

export default App
