import React from "react"
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  getContactsOnEvent,
  setCurrentPage,
} from "../../redux/slices/contacts/contactsSlices"
import { getEvent } from "../../redux/slices/events/eventsSlice"
import {
  IconAlertCircle,
  IconArrowLeft,
  IconBalance,
  IconBell,
  IconBtnCreate,
  IconCheck,
  IconProfileInfo,
  IconSearch,
} from "../../components/utils/Icons/CustomIcons"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import GuestsList from "../../components/Reminders/GuestsList"
import Paginator from "../../components/utils/Pagination/Pagination"
import GuestsFilter from "../../components/Reminders/GuestFilter"
import GuestsSearch from "../../components/Reminders/GuestsSearch"
import GuestsPopup from "../../components/Reminders/GuestsPopup"
import PreviewModal from "../../components/Reminders/PreviewModal/PreviewModal"

const RemindersPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { event_id } = useParams()
  const navigate = useNavigate()
  const [width, setWidth] = useState<number>(window.innerWidth)
  const { current_page, total_pages, limit } = useAppSelector(
    (state) => state.contacts,
  )
  const { balance } = useAppSelector((state) => state.auth)
  const event = useAppSelector((state) => state.events.currentEvent)
  const { data_on_event } = useAppSelector((state) => state.contacts)
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [filter, setFilter] = useState({
    sort: "",
    order: "",
    searchValue: "",
    reminderNumber: "",
    statusNumber: "0,1,2,5,6",
    id: Number(event_id),
  })
  const [checked, setChecked] = useState([])
  const [allGuestsCheked, setAllGuestsChecked] = useState([])

  const [checkedFilter, setCheckedFilter] = useState("")
  const [checkedReminder, setCheckedReminder] = useState("")
  const [value, setValue] = useState<string | undefined>("Reminder only")

  const notify = (text: string, color: boolean) => {
    color
      ? toast.warning(text, {
          position: "top-right",
          autoClose: 3000,
        })
      : toast.success(text, {
          position: "top-right",
          autoClose: 3000,
        })
  }

  useEffect(() => {
    const updateWidowSizes = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", updateWidowSizes)
    return () => {
      window.removeEventListener("resize", updateWidowSizes)
    }
  }, [])

  useEffect(() => {
    dispatch(getEvent(Number(event_id)))
  }, [dispatch, event_id])

  const onPageChanged = (page: number) => {
    dispatch(setCurrentPage(page))
    dispatch(getContactsOnEvent(filter))
  }

  const getBalance = () => {
    let sum = 0
    data_on_event.forEach((contact) => {
      if (checked.some((id) => id == contact.id)) {
        contact.free_reminder === false ? (sum += 1) : sum
      }
    })
    return sum
  }

  const onSend = () => {
    if (checked.length > 0 && getBalance() <= balance) {
      setShowModal(true)
    } else if (checked.length > 0 && getBalance() > balance) {
      notify("Please top up your balance", true)
    } else {
      notify("Please select a guest", true)
    }
  }

  return (
    <div className="reminders">
      <div className="container">
        <div className="reminders-page__link">
          <Link
            className="d-flex align-items-center reminders-page__link-container"
            to={`/${event_id}`}
          >
            <IconArrowLeft />
            <span className="reminders-page__link-text">
              {t("buttons.back")} {event?.name}
            </span>
          </Link>
        </div>
        <div className="reminders-title">
          <h2 className="caption-two">{t("reminders.titlePageReminders")}</h2>
          <Button
            className="btn btn-dark reminders-title__btn"
            onClick={onSend}
          >
            {t("buttons.send")}
          </Button>
        </div>

        <div className="reminders-row">
          <div className="reminders-col reminders-col--balance bl-default">
            <div className="reminders-left">
              <h2 className="caption caption-three">
                {t("reminders.availableBalance")}
              </h2>

              <div className="reminders-info">
                <IconBalance />
                <span className="reminders-balance__value">
                  {balance} {t("reminders.credits")}
                </span>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className="reminders-tooltip" id="tooltipCredits">
                      {t("reminders.tooltip.credits")}
                    </Tooltip>
                  }
                >
                  <div className="reminders-tooltip__content">
                    <IconProfileInfo color="dark" />
                  </div>
                </OverlayTrigger>
              </div>
            </div>

            <Button className="btn btn-dark" onClick={() => navigate('/credits')}>
              <IconBtnCreate />
              {t("buttons.topUP")}
            </Button>
          </div>

          <div className="reminders-col reminders-col--plan bl-default">
            <div className="reminders-left">
              <h2 className="caption caption-three">
                {t("reminders.freeReminder")}
              </h2>

              <div className="reminders-info">
                <IconCheck />

                <span className="reminders-balance__value">
                  {t("reminders.useCredits")}
                </span>
              </div>
            </div>

            <IconBell />
          </div>

          <div className="reminders-col reminders-col--content bl-default">
            <div className="reminders-col__head">
              <h2 className="caption caption-three">{t("reminders.guests")}</h2>
              <div className="reminders-guests__buttons">
                {!show ? (
                  <button
                    className="d-flex justify-content-center align-items-center event-page__btn"
                    onClick={() => setShow(true)}
                  >
                    <IconSearch />
                  </button>
                ) : (
                  <GuestsSearch
                    filter={filter}
                    setFilter={setFilter}
                    name={"desktop"}
                  />
                )}
                <GuestsFilter
                  filter={filter}
                  setFilter={setFilter}
                  checkedFilter={checkedFilter}
                  setCheckedFilter={setCheckedFilter}
                  checkedReminder={checkedReminder}
                  setCheckedReminder={setCheckedReminder}
                />
              </div>
            </div>
            <GuestsSearch
              filter={filter}
              setFilter={setFilter}
              name={"mobile"}
            />
            <GuestsPopup name={"mobile"} value={value} setValue={setValue} />
            {checked.length > 0 && getBalance() <= balance && (
              <div className="reminders-select__add mobile">
                <IconCheck color="dark" />
                <span className="reminders-select__add-text">
                  {t("reminders.textOk")}
                </span>
              </div>
            )}
            {checked.length > 0 && getBalance() > balance && (
              <div className="reminders-select__add mobile">
                <IconAlertCircle />
                <span className="reminders-select__add-text">
                  {t("reminders.textNo1")}
                  <a href="#">{t("reminders.textNo2")}</a>
                </span>
              </div>
            )}
            <GuestsList
              filter={filter}
              setFilter={setFilter}
              width={width}
              limit={limit}
              checked={checked}
              setChecked={setChecked}
              allGuestsChecked={allGuestsCheked}
              setAllGuestsChecked={setAllGuestsChecked}
              value={value}
              setValue={setValue}
            />
          </div>
        </div>
      </div>
      <PreviewModal
        show={showModal}
        setShow={setShowModal}
        checked={checked}
        setChecked={setChecked}
        setAllGuestsChecked={setAllGuestsChecked}
        value={value === "Reminder only" ? 0 : 1}
        filter={filter}
      />
      <ToastContainer
        style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
      />
      <div className="event-paginator">
        {total_pages !== 0 && (
          <Paginator
            total_pages={total_pages}
            current_page={current_page}
            onPageChanged={onPageChanged}
          />
        )}
      </div>
      <div className="container">
        <button
          type="button"
          className="btn btnloadMore d-lg-none d-sm-flex btn btn-primary event-loadmore"
        >
          {t("buttons.loadMore")}
        </button>
      </div>
    </div>
  )
}

export default RemindersPage
