import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import SelectGuest from "./SelectGuest"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../redux/store"
import {
  changeContact,
  getContactsOnEvent,
  setLoading,
} from "../../../redux/slices/contacts/contactsSlices"
import { updateInvite } from "../../../redux/slices/events/eventsSlice"
import styles from "./EditGuest.module.scss"

const EditGuest = ({
  guest,
  setGuest,
  show,
  setShow,
  validation,
  setValidation,
  filter,
  event_id,
  notify,
}: any) => {
  const { t } = useTranslation()

  const [newGuest, setNewGuest] = useState({
    id: guest.id,
    first_name: "",
    last_name: "",
    suffix: "",
    phone: "",
    email: "",
  })
  const dispatch = useAppDispatch()
  const isEmpty = (val: any) => {
    if (!val) {
      return "empty"
    }
  }

  const handeInput = (input: any, value: any) => {
    setGuest({ ...guest, [input]: value })
  }

  useEffect(() => {
    setNewGuest({
      ...newGuest,
      id: guest.id,
      first_name: guest.first_name,
      last_name: guest.last_name,
      suffix: guest.suffix,
      phone: guest.phone,
      email: guest.email,
    })
  }, [guest])

  const showToast = (result: any) => {
    setValidation(result)
    notify((Object.values(result) as string[][])[0][0], true)
  }

  const saveEditGuest = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    if (guest.quantity_members >= 0 && guest.quantity_members <= 10) {
      dispatch(changeContact(newGuest)).then((result: any) => {
        if (result.error) {
          showToast(result.payload)
        } else {
          dispatch(
            updateInvite({
              event_id: event_id,
              contact_id: guest.id,
              quantity_members: guest.quantity_members,
            }),
          ).then((result: any) => {
            if (result.error) {
              (Object.values(result.payload) as string[][])[0][0]
            } else {
              dispatch(setLoading())
              dispatch(getContactsOnEvent(filter))
              notify("Data changed successfully", false)
              setShow(false)
              setNewGuest({
                ...newGuest,
                id: "",
                first_name: "",
                last_name: "",
                suffix: "",
                phone: "",
                email: "",
              })
            }
          })
        }
      })
    } else {
      setValidation({ invites_quantity: "Value must be between 0 and 10" })
      notify("Value must be between 0 and 10", true)
    }
  }

  const onHide = () => {
    setShow(false)
    setNewGuest({
      ...newGuest,
      id: "",
      first_name: "",
      last_name: "",
      suffix: "",
      phone: "",
      email: "",
    })
    setValidation([])
  }

  return (
    <Modal className={styles.editPopup} show={show} onHide={onHide}>
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            {t("contacts.editGuestPopup.title")}
          </h2>
          <span className={styles.text}>
            {t("contacts.editGuestPopup.text")}
          </span>

          <form action="" className={styles.form} onSubmit={saveEditGuest}>
            <span className={styles.title}>Guest Information</span>
            <SelectGuest
              placeholder={t("placeholder.suffix")}
              value={guest.suffix}
              validation={validation}
              handeInput={handeInput}
            />

            <div className={`profile-input ${styles.input}`}>
              <input
                type="text"
                value={guest.first_name}
                onChange={(event) => {
                  handeInput("first_name", event.target.value)
                }}
                className={isEmpty(guest.first_name)}
                placeholder={t("placeholder.firstName")}
              />

              {validation?.first_name && (
                <span className="input-item__error error mt-1">
                  {validation?.first_name}
                </span>
              )}
            </div>

            <div className={`profile-input ${styles.input}`}>
              <input
                type="text"
                value={guest.last_name}
                onChange={(event) => {
                  handeInput("last_name", event.target.value)
                }}
                className={isEmpty(guest.last_name)}
                placeholder={t("placeholder.lastName")}
              />

              {validation?.last_name && (
                <span className="input-item__error error mt-1">
                  {validation?.last_name}
                </span>
              )}
            </div>

            <div className={`profile-input ${styles.input}`}>
              <input
                type="text"
                value={guest.email}
                className={isEmpty(guest.email)}
                onChange={(event) => {
                  handeInput("email", event.target.value)
                }}
                placeholder={t("placeholder.email")}
              />

              {validation?.email && (
                <span className="input-item__error error mt-1">
                  {validation?.email}
                </span>
              )}
            </div>

            <div className={`profile-input ${styles.input}`}>
              <input
                type="text"
                value={guest.phone}
                onChange={(event) => {
                  handeInput("phone", event.target.value)
                }}
                className={isEmpty(guest.phone)}
                placeholder={t("placeholder.phoneNumber")}
              />

              {validation?.phone && (
                <span className="input-item__error error mt-1">
                  {validation?.phone}
                </span>
              )}
            </div>

            <span className={styles.title}>Additional Invites</span>
            <div
              className={`profile-input ${styles.input} ${
                validation?.invites_quantity && "input-error"
              }`}
            >
              <input
                type="number"
                value={guest.quantity_members}
                onChange={(event) => {
                  handeInput("quantity_members", event.target.value)
                }}
                className={isEmpty(guest.invites_quantity)}
                placeholder={t("placeholder.invitesQuantity")}
              />

              {validation?.invites_quantity && (
                <span className="input-item__error error mt-1">
                  {validation?.invites_quantity}
                </span>
              )}
            </div>

            <div className={styles.controls}>
              <span className="btn btn-simple" onClick={onHide}>
                {t("buttons.cancel")}
              </span>
              <button type="submit" className="btn btn-dark">
                {t("buttons.save")}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EditGuest
