import { useNavigate, useParams } from "react-router-dom"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import {
  sendTestInvitation,
} from "../../../redux/slices/events/eventsSlice"
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import styles from "./TestModal.module.scss"

type Props = {
  show: boolean
  setShow: any
  personal: {
    first_name: string
    last_name: string
    suffix: string
    phone: string
  }
}

const TestModal = ({ show, setShow, personal }: Props) => {
  const { t } = useTranslation()
  const event = useAppSelector((state) => state.events.currentEvent)
  const dispatch = useAppDispatch()
  const { event_id } = useParams()
  const { lang } = useAppSelector((state) => state.auth)
  const navigate = useNavigate()

  const onSendNow = () => {
    dispatch(
      sendTestInvitation({
        event_id: Number(event_id),
        first_name: personal.first_name,
        last_name: personal.last_name,
        phone: personal.phone,
        suffix: personal.suffix,
      }),
    ).then(() => {
      navigate(`/${event_id}/test-the-invitation/status`)
    })
  }

  return (
    <Modal
      className={`${styles.testModal} modal-test__preview`}
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header className={styles.close} closeButton></Modal.Header>
      <Modal.Body>
        <div className={styles.body}>
          <h2 className={`caption ${styles.caption}`}>
            {t("test.preview.title")}
          </h2>
          <div className={styles.phone}>
            <div className="preview-phone">
              <div className="preview-content">
                <div className="preview-content__wrap">
                  <div className="preview-content__inner">
                    <picture className="picture">
                      <img
                        src={
                          event?.pattern
                            ? event?.pattern
                            : "/vector/no-foto.svg"
                        }
                        alt=""
                      />
                    </picture>
                    {lang === "en" ? (
                      <span>
                        <p>{t("test.preview.name")}</p>
                        <p>
                          We are pleased to invite you to {event?.name} event
                          (reminder)
                        </p>
                      </span>
                    ) : (
                      <p> فلان الفلاني نحن سعداء بدعوتك إلى {event?.name}</p>
                    )}
                    <div className="preview-content__bottom">
                      <a href="#">www.mazoom.sa</a>
                      <span className="date">11.14 AM</span>
                    </div>
                  </div>

                  <div className="preview-invitation__controls">
                    <button className="btn" disabled>
                      {t("test.preview.decline")}
                    </button>
                    <button className="btn">{t("test.preview.confirm")}</button>
                    <button className="btn">
                      {t("test.preview.location")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.controls}>
            <button onClick={onSendNow} className={`${styles.btn}`}>
              Send Now
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default TestModal
