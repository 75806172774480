import { useState, useEffect, SyntheticEvent } from "react"
import { useTranslation } from "react-i18next"
import { ToastContainer, toast } from "react-toastify"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import {
  getPreview,
  clearImg,
  createEvent,
  eventDesign,
  clearEventId,
  getEvent,
  setNewEventId,
  getQrDesign,
  getQrImage,
  clearQrId,
} from "../../redux/slices/events/eventsSlice"
import { useNavigate, useParams } from "react-router-dom"
import CreateEventForm from "./CreateEventForm"
import CreateEventPreview from "./CreateEventPreview"
import CreateEventPreviewOpen from "./CreateEventPreviewOpen"
import { useForm } from "react-hook-form"

interface IForm {
  name: string
  address: string
  type: string
  file: string
  start_datetime: string
  language: string
}

const notify = (text: string, color: boolean) => {
  color
    ? toast.error(text, {
        position: "top-right",
        autoClose: 3000,
      })
    : toast.success(text, {
        position: "top-right",
        autoClose: 3000,
      })
}

const EditEventPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { lang, error } = useAppSelector((state) => state.auth)
  const { new_event_img, preview, currentEvent, new_event_id } = useAppSelector(
    (state) => state.events,
  )
  const { event_id } = useParams()

  const [language, setLanguage] = useState(lang)
  const [showOpen, setShowOpen] = useState(false)
  const [address, setAddress] = useState({ venue: "", address: "" })

  const {
    register,
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<IForm>({})

  const defaultEvent = {
    name: "",
    address: {
      address: "",
      lat: 0,
      lng: 0,
    },
    type: -1,
    event_created_date: lang,
    language: "",
    img: {
      path: new_event_img?.file_path,
      id: new_event_img?.id,
    },
    invitation_show_qr_code: false,
    qr_wallet: false,
    is_open_event: false,
    start_datetime: "",
    venue: "",
    qr_code_design_id: null
  }

  const [event, setEvent] = useState<any>(defaultEvent)

  const onCancelHandle = () => {
    setEvent(defaultEvent)
    navigate("/events")
  }

  useEffect(() => {
    dispatch(getEvent(Number(event_id)))
    dispatch(setNewEventId(Number(event_id)))
  }, [dispatch, event_id])

  useEffect(() => {
    currentEvent &&
      setEvent({
        name: currentEvent?.name,
        address: {
          address:
            currentEvent.venue !== "" && currentEvent.venue !== null
              ? currentEvent.venue + ", " + currentEvent.address.address
              : currentEvent.address.address,
          lat: currentEvent.address.lat,
          lng: currentEvent.address.lng,
        },
        type: currentEvent.type,
        event_created_date: new Date(currentEvent.event_created_date),
        language: currentEvent.language,
        img: {
          path: currentEvent.pattern,
          id: currentEvent.event_id,
        },
        invitation_show_qr_code: currentEvent.invitation_show_qr_code === 1 ? true : false,
        qr_wallet: false,
        is_open_event: currentEvent.is_open_event ? 1 : 0,
        start_datetime: new Date(currentEvent?.start_datetime),
        venue: "",
        qr_code_design_id: currentEvent.qr_code_design_id
      })
    currentEvent &&
      setAddress({
        venue: currentEvent?.venue,
        address: currentEvent?.address.address,
      })
    currentEvent && setLanguage(currentEvent.language)
  }, [dispatch, currentEvent])

  useEffect(() => {
    dispatch(getPreview())

    return () => {
      dispatch(clearImg())
      setEvent(defaultEvent)
      dispatch(clearQrId())
    }
  }, [])

  useEffect(() => {
    setEvent({
      ...event,
      img: {
        path: new_event_img.file_path,
        id: new_event_img.id,
      },
    })
  }, [new_event_img])

  const onSubmit = (e: SyntheticEvent, manage: string) => {
    e.preventDefault()
    clearErrors()
    if (new_event_img.id === 0 && new_event_id === null) {
      setError("file", { type: "custom", message: "This field is required" })
    } else if (event.name === "" && event.address.address === "") {
      setError("name", { type: "custom", message: "This field is required" })
      setError("address", { type: "custom", message: "This field is required" })
    } else if (event.name === "") {
      setError("name", { type: "custom", message: "This field is required" })
    } else if (event.address.address === "") {
      setError("address", { type: "custom", message: "This field is required" })
    } else {
      dispatch(
        createEvent({
          ...event,
          address: {
            ...event.address,
            address: address.address ? address.address : address.venue,
          },
          venue: address.address ? address.venue : "",
        }),
      )
        .then((data: any) => {
          if (data.payload.errors) {
            event.address.address === "" &&
              setError("address", {
                type: "custom",
                message: "This field is required",
              })
            Object.entries(data.payload.errors).forEach(
              ([field, messages]: any) => {
                messages.forEach((message: any) => {
                  setError(field, { type: "custom", message })
                })
              },
            )
            notify((Object.values(data.payload.errors) as string[])[0][0], true)
          } else {
            let eventD = {
              event_id: data.payload.data.event_id,
              pattern_id: new_event_img.id
                ? new_event_img.id
                : currentEvent?.pattern_id,
            }
            new_event_img.id === 0 && currentEvent === null
              ? notify("The selected pattern id is invalid", true)
              : dispatch(eventDesign(eventD)).then((res: any) => {
                  if (res.payload.data) {
                    if (manage === "") {
                      dispatch(clearEventId())
                      notify("Event created", false)
                      navigate("/events")
                      dispatch(clearQrId())
                    } else if (manage === "qr") {
                      dispatch(clearQrId())
                      event_id && navigate(`/qr-design/${event_id}`)
                    } else {
                      setShowOpen(true)
                    }
                  }
                })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <div className="createEventPage">
      <div className="container">
        <h2 className="caption-two">Edit Event</h2>
        {currentEvent && (
          <div className="createEventForm bl-default">
            <CreateEventForm
              register={register}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              address={address}
              setAddress={setAddress}
              event={event}
              setEvent={setEvent}
              new_event_img={new_event_img}
              error={error}
              onCancelHandle={onCancelHandle}
              language={language}
              setLanguage={setLanguage}
              setShowOpen={setShowOpen}
              showOpen={showOpen}
            />
            {event.is_open_event ? (
              <CreateEventPreviewOpen
                event={event}
                new_event_img={new_event_img}
                loadPreview={preview}
                language={language}
                onSubmit={onSubmit}
              />
            ) : (
              <CreateEventPreview
                event={event}
                new_event_img={new_event_img}
                loadPreview={preview}
                language={language}
                onSubmit={onSubmit}
              />
            )}
            <div className="createEventForm__controls">
              <span className="btn btn-simple" onClick={onCancelHandle}>
                {t("buttons.cancel")}
              </span>
              <button
                type="submit"
                onClick={(e) => onSubmit(e, "")}
                className="btn btn-dark"
              >
                {t("buttons.save")}
              </button>
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
      />
    </div>
  )
}

export default EditEventPage
